<template>
    <b-container v-if="insurance">
        <b-row>
            <b-col cols="4" class="flex-center">
                <h6 class="text-center">
                    Bosna i Hercegovina<br/>
                    FEDERACIJA BiH<br/>
                    Federalno ministarstvo financija/finansija<br/>
                    POREZNA UPRAVA
                </h6>
            </b-col>
            <b-col cols="4" class="flex-center flex-column py-4">
                <h6 class="text-center">Obrazac 2002</h6>
                <h4 class="text-center">Specifikacija uz uplatu doprinosa poduzetnika s prebivalištem u Federaciji BiH</h4>
            </b-col>
            <b-col cols="4" class="flex-center">
                <h6 class="text-center">
                    Za službenu<br/>
                    upotrebu<br/>
                </h6>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col md="12">
                <h5 class="text-center my-4">
                    Dio 1 - Podaci o registriranoj djelatnosti
                </h5>
            </b-col>
        </b-row>
        <b-form v-on:submit.prevent="editInsurance">
            <b-row>
                <b-col md="5" class="py-4">
                    1) Naziv: {{insurance.client.name}}
                </b-col>
                <b-col md="7" class="py-4">
                    2) JIB/JMB: {{insurance.client.owner_jmbg}}
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" class="flex-center">
                    <b-form-group
                        class="w-100"
                        label="3) Operacija:">
                        <b-form-radio-group
                            stacked
                            id="operation"
                            name="operation"
                            v-model="insurance.operation"
                            required>
                            <b-form-radio value="1">Prijava podataka</b-form-radio>
                            <b-form-radio value="2">Izmjena podataka za već predate specifikacije 2002 poslane fondovima</b-form-radio>
                            <b-form-radio value="3">Brisanje podataka za već predate specifikacije 2002 poslane fondovima</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="flex-center">
                    <b-form-group
                        class="w-100"
                        horizontal
                        :label-cols="4"
                        breakpoint="md"
                        label="4) Period (od/do) (dan/mjesec/godina): "
                        label-for="period">
                        <b-form-input :value="insurance.date_from"
                                      id="date_from"
                                      type="date"
                                      size="sm"
                                      name="date_from"
                                      required></b-form-input>
                        <b-form-input :value="insurance.date_to"
                                      id="date_to"
                                      type="date"
                                      size="sm"
                                      name="date_to"
                                      required></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4" class="d-flex align-items-center py-4">
                    5) Adresa: {{insurance.client.address}}
                </b-col>
                <b-col md="4" class="d-flex align-items-center py-4">
                    6) Općina: {{selectedClient.city.township.name}}
                </b-col>
                <b-col md="4" class="flex-center">
                    <b-form-group
                        class="w-100"
                        horizontal
                        :label-cols="4"
                        breakpoint="md"
                        label="7) Broj zaposlenih: "
                        label-for="employed">
                        <b-form-input :value="insurance.employed" id="employed" type="number" size="sm" name="employed" required></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="py-4">
                    8) Vrsta djelatnosti (šifra i naziv): {{insurance.client.activity.code + ' ' + insurance.client.activity.name}}
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" class="flex-center">
                    <b-form-group
                        class="w-100"
                        label="9) Vrsta samostalne djelatnosti (označiti odgovarajuće polje):">
                        <b-form-radio-group
                            v-model="insurance.activity_type"
                            stacked
                            id="activity_type"
                            name="activity_type">
                            <b-form-radio value="1">a) sloboda zanimanja</b-form-radio>
                            <b-form-radio value="2">b) djelatnost obrta</b-form-radio>
                            <b-form-radio value="3">c) nisko akumulacijska</b-form-radio>
                            <b-form-radio value="4">d) poljoprivrede i šumarstva</b-form-radio>
                            <b-form-radio value="5">e) trgovac pojedinac</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="flex-center">
                    <b-row>
                        <b-col cols="12" >
                            <b-form-group
                                class="w-100"
                                label="10) Dohodak se utvrđuje na osnovu (oznaćiti odgov. polje):">
                                <b-form-radio-group
                                    v-model="insurance.income_type"
                                    stacked
                                    id="income_type"
                                    name="income_type">
                                    <b-form-radio value="1">a) poslovnih knjiga</b-form-radio>
                                    <b-form-radio value="2">b) paušalna</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                class="w-100"
                                horizontal
                                :label-cols="4"
                                breakpoint="md"
                                label="11) Osnovica za obračun: "
                                label-for="base">
                                <b-form-input :value="insurance.base" id="base" type="text" size="md" name="base" required></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4" class="flex-center">
                    <b-form-group
                        class="w-100"
                        horizontal
                        :label-cols="4"
                        breakpoint="md"
                        label="12) Broj radnih sati: "
                        label-for="work_hours">
                        <b-form-input :value="insurance.work_hours" id="work_hours" type="text" size="sm" name="work_hours" required></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="4" class="flex-center">
                    <b-form-group
                        class="w-100"
                        horizontal
                        :label-cols="4"
                        breakpoint="md"
                        label="13) Broj rad. sati na bolov. : "
                        label-for="work_hours_bol">
                        <b-form-input :value="insurance.work_hours_bol" id="work_hours_bol" type="text" size="sm" name="work_hours_bol" required></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="4" class="flex-center">
                    <b-form-group
                        class="w-100"
                        horizontal
                        :label-cols="4"
                        breakpoint="md"
                        label="14) Datum uplate doprinosa (dan/mjesec/godina): "
                        label-for="pay_date">
                        <b-form-input :value="insurance.pay_date" id="pay_date" type="date" size="sm" name="pay_date" required></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row class="my-4">
                <b-col md="12" class="flex-center">
                    <h5 class="text-center my-4">Dio 2 - Podaci o poduzetniku</h5>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" class="py-4">
                    15) Prezime i ime: {{insurance.client.owner}}
                </b-col>
                <b-col md="6" class="py-4">
                    16) JMB: {{insurance.client.owner_jmbg}}
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" class="py-4">
                    17) Adresa: {{insurance.client.address}}
                </b-col>
                <b-col md="4" class="d-flex align-items-center py-4">
                    18) Općina: {{selectedClient.city.township.name}}
                </b-col>
            </b-row>
            <b-row class="my-4">
                <b-col md="12" class="flex-center">
                    <h5 class="text-center my-4">Dio 3 - Podaci o doprinosima</h5>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="1" class="flex-center">
                    <h5 class="text-center my-4">Red. broj</h5>
                </b-col>
                <b-col md="6" class="flex-center">
                    <h5 class="text-center my-4">Opis</h5>
                </b-col>
                <b-col md="2" class="flex-center">
                    <h5 class="text-center my-4">Stopa</h5>
                </b-col>
                <b-col md="3" class="flex-center">
                    <h5 class="text-center my-4">Iznos</h5>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="1" class="flex-center">
                    <h6 class="text-center my-1">a</h6>
                </b-col>
                <b-col md="6" class="flex-center">
                    <h6 class="text-center my-1">b</h6>
                </b-col>
                <b-col md="2" class="flex-center">
                    <h6 class="text-center  my-1">c</h6>
                </b-col>
                <b-col md="3" class="flex-center">
                    <h6 class="text-center my-1">d</h6>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="1" class="flex-center py-4">
                    <h6 class="text-center my-1">19</h6>
                </b-col>
                <b-col md="4" class="flex-center py-4">
                    <h6 class="text-center my-1">Doprinos za mirovinsko i zdravstveno osiguranje</h6>
                </b-col>
                <b-col md="2" class="flex-center py-4">
                    <h6 class="text-center my-1">712112</h6>
                </b-col>
                <b-col md="2" class="flex-center py-4">
                    <b-form-input :value="insurance.mir_zdrav" id="mir_zdrav" type="text" size="sm" name="mir_zdrav" required></b-form-input>
                </b-col>
                <b-col md="3" class="flex-center py-4">
                    <b-form-input :value="insurance.mir_zdrav_izn" id="mir_zdrav_izn" type="text" size="sm" name="mir_zdrav_izn" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="1" class="flex-center py-4">
                    <h6 class="text-center my-1">20</h6>
                </b-col>
                <b-col md="4" class="flex-center py-4">
                    <h6 class="text-center my-1">Doprinos za zdravstveno osiguranje</h6>
                </b-col>
                <b-col md="2" class="flex-center py-4">
                    <h6 class="text-center my-1">712111</h6>
                </b-col>
                <b-col md="2" class="flex-center py-4">
                    <b-form-input :value="insurance.zdrav" id="zdrav" type="text" size="sm" name="zdrav" required></b-form-input>
                </b-col>
                <b-col md="3" class="flex-center py-4">
                    <b-form-input :value="insurance.zdrav_izn" id="zdrav_izn" type="text" size="sm" name="zdrav_izn" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="1" class="flex-center py-4">
                    <h6 class="text-center my-1">21</h6>
                </b-col>
                <b-col md="4" class="flex-center py-4">
                    <h6 class="text-center my-1">Doprinos za osiguranje od neuposlenosti</h6>
                </b-col>
                <b-col md="2" class="flex-center py-4">
                    <h6 class="text-center my-1">712113</h6>
                </b-col>
                <b-col md="2" class="flex-center py-4">
                    <b-form-input :value="insurance.neup" id="neup" type="text" size="sm" name="neup" required></b-form-input>
                </b-col>
                <b-col md="3" class="flex-center py-4">
                    <b-form-input :value="insurance.neup_izn" id="neup_izn" type="text" size="sm" name="neup_izn" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="1" class="flex-center py-4">
                    <h6 class="text-center my-1">22</h6>
                </b-col>
                <b-col md="8" class="flex-center py-4">
                    <h6 class="text-center my-1">UKUPNO DOPRINOSI (19 + 20 + 21)</h6>
                </b-col>
                <b-col md="3" class="flex-center py-4">
                    {{insurance.total}}
                </b-col>
            </b-row>

            <b-row class="my-4">
                <b-col md="12" class="flex-center">
                    <h5 class="text-center my-4">Dio 4 - Izjava poduzetnika</h5>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="flex-center">
                    <p class="text-center">Upoznat sam sa svim sankcijama propisanim Zakonom i drugim propisima, izjavljujem da su podaci navedeni u ovoj
                        specifikaciji uključujući sve priloge potpuni, točni i jasni.</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="9">
                    <h6 class="my-2">Potpis poreznog obveznika: </h6>
                </b-col>
                <b-col md="3">
                    <h6 class="my-2 text-left">Datum: </h6>
                </b-col>
            </b-row>
            <b-row class="flex-center no-border">
                <b-btn type="submit" variant="primary" class="my-4 w-50">Potvrdi</b-btn>
            </b-row>
        </b-form>
    </b-container>
</template>

<script>
    export default {
        data() {
            return {
                insurance: null
            }
        },
        methods: {
            editInsurance: function (e) {
                const formData = new FormData(e.target);
                axiOsta.post(api.insurances + this.$route.params.id, formData).then( response => {
                        if (response.status === 200) {
                            this.$router.push('/insurances/' + response.data.id);
                        }
                    }
                )
            },
            getInsurance() {
                axiOsta.get(api.insurances + this.$route.params.id)
                    .then(response => {
                        if (response.status === 200) {
                            this.insurance = response.data;
                        }
                    });
            }
        },
        created() {
            this.getInsurance();
        }
    }
</script>
